@tailwind base;
@tailwind components;
@tailwind utilities;

.special-background {
  color: #fff;
  background-position: right;
  background-repeat: no-repeat;
  background: #3e7e8e;
  background: linear-gradient(135deg, #2a75b2 0%, #2a75b2 35%, #004a83 35%);
  color: #ffffff;
  background-size: 200% 100%;
  -webkit-box-shadow: inset 0 0 0 0 #d80286;
  box-shadow: inset 0 0 0 0 #d80286;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.special-background-animate:hover {
  background-position: right bottom;
  color: #fff;
}
